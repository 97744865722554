import React, { PureComponent } from 'react';
import { Alert, Row, Col, Button, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import marked from 'marked';
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { ObjectID } from 'bson'
const ReactMarkdown = require('react-markdown')
const dbname = 'chants_desperance'

export default class Edit extends PureComponent {
  constructor(props) {
    super(props)
    const i = new URLSearchParams(this.props.location.search)
    this.markdownRef = React.createRef();
    this.state = {
      textContent: "",
      id: i ? i.get('id') : null,
      song: null,
      isSaved: false,
      db: this.props.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(dbname)
    }

  }
  _onTextChange = (e) => {

    let _song = this.state.song
    _song.markdown_lyrics = e.target.value
    _song.html_lyrics = marked(e.target.value)
    this.setState({
      textContent: e.target.value,
      song: _song
    })
  }
  componentWillMount() {
    if (this.state.id)
      this._fetchSong(this.state.id)
  }
  _fetchSong = async (_id) => {


    const coll = this.state.db.collection('songs')
    const song = await coll.find({ "_id": new ObjectID(_id) }).first()

    this.setState({
      song,
      textContent: song.markdown_lyrics
    })

  }
  _onFieldChange = (e) => {
    let _song = this.state.song
    console.log(e.target)
    switch (e.target.id) {
      case 'title':
        _song.title = e.target.value;
        break;
      case 'num':
        _song.num = e.target.value;
        break;
      case 'language':
        _song.language = e.target.value;
        break;
      default:
        break;
    }

    this.setState({
      song: _song
    })
  }
  _saveChanges = async () => {
    const song = this.state.song
    song.updated_at = new Date()


    const coll = this.state.db.collection('songs')
    await coll.updateOne({ "_id": new ObjectID(song._id) }, song)

    this.setState({ isSaved: true })
    setTimeout(() => {
      this.setState({
        isSaved: false
      })
    }, 3000)
  }
  render() {
    return (
      <div style={{ paddingLeft: 20, paddingRight: 20 }}>{this.state.song ?
        <div>
          {this.state.isSaved ? <Alert message="Success Text" type="success" /> : null}
          <Row type="flex" justify="start" style={{ paddingBottom: 20 }}>
            <Col span={12}>
              <Row><span>Title</span><Input id="title" defaultValue={this.state.song.title} onChange={this._onFieldChange} /></Row>
              <Row><span>Num</span><Input id="num" defaultValue={this.state.song.num} onChange={this._onFieldChange} /></Row>
              <Row><span>Abbrv</span><Input id="book_abbrv" defaultValue={this.state.song.book_abbrv} /></Row>
              <Row><span>Language</span><Input id="language" defaultValue={this.state.song.language} onChange={this._onFieldChange} /></Row>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <TextArea autosize={{ minRows: 20 }} style={{ width: '95%' }} onChange={this._onTextChange} defaultValue={this.state.song.markdown_lyrics} />
            </Col>
            <Col md={12}>
              <ReactMarkdown source={this.state.textContent} escapeHtml={true} />
            </Col>
          </Row>   <Button onClick={this._saveChanges}>Save</Button>
        </div> : <p>No Song</p>}

      </div>
    );
  }
}

