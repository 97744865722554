import React, { PureComponent } from 'react'
import { Form, Icon, Input, Button, Row, Col, message } from 'antd';
import { Link } from 'react-router-dom'
import { UserPasswordCredential } from 'mongodb-stitch-browser-sdk';

const FormItem = Form.Item;

class NormalLoginForm extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: ''
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.authEmail(values.userName, values.password)
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit} className="login-form">
        <FormItem>
          {getFieldDecorator('userName', {
            rules: [{ required: true, message: 'Please input your username!' }],
          })(
            <Input size="large" prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} onChange={(e) => this.setState({ email: e.target.value })} />} placeholder="Username" />
          )}
        </FormItem>
        <FormItem>
          {getFieldDecorator('password', {
            rules: [{ required: true, message: 'Please input your Password!' }],
          })(
            <Input size="large" prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} onChange={(e) => this.setState({ password: e.target.value })} />} type="password" placeholder="Password" />
          )}
        </FormItem>
        <FormItem>
          <Button size="large" type="primary" htmlType="submit" className="login-form-button">
            Log in
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const LoginForm = Form.create()(NormalLoginForm)


class Login extends PureComponent {
  constructor(props) {
    super(props)
    this.loginForm = React.createRef();
  }

  authEmail = async (email, password) => {
    const credential = new UserPasswordCredential(email, password)
    try {
      const user = await this.props.client.auth.loginWithCredential(credential)
      this.props.history.push("/")
    }
    catch (err) {
      message.error(err.message);
      this.loginForm.current.resetFields()
    }

  }
  render() {
    return (
      <Row type="flex" justify="center">
        <Col xs={22} md={16} className="st-login">
          < LoginForm{...this.props}  authEmail={this.authEmail} ref={this.loginForm} />
        </Col>
      </Row>
    )
  }
}

export default Login