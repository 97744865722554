import React from 'react'
import BaseContainer from './modules/BaseContainer'
import {
  Route,
  Switch,
  BrowserRouter as Router
} from 'react-router-dom'
import { render } from "react-dom";
import Login from './modules/Login';

/*import Confirm from './modules/callback/Confirm'
import Callback from './modules/callback/Callback'
import Password from './modules/password/Password'
import Reset from './modules/reset/Reset'
import LoginContainer from "./modules/login/LoginContainer"
import RegistrationContainer from "./modules/registration/RegistrationContainer"
*/

const routes = (client) => (
  < Router >
    <Switch>
      <Route path="/login" render={(props) => <Login client={client} {...props} />} />
      <Route path="/" render={(props) => <BaseContainer client={client} {...props} />} />
    </Switch>

  </Router >
)

/*
      <Switch>
        <Route path="/login" render={(props) => <LoginContainer auth={auth} {...props} />} />
        <Route path="/callback" render={(props) => <Callback auth={auth} {...props} />} />
        <Route path="/register" render={(props) => <RegistrationContainer auth={auth} {...props} />} />
        <Route path="/confirm-email" render={(props) => <Confirm auth={auth} {...props} />} />
        <Route path="/forgot-password" render={(props) => <Password auth={auth} {...props} />} />

        <Route path="/reset-password" render={(props) => <Reset auth={auth} {...props} />} />
        <Route path="/" render={(props) => <BaseContainer auth={auth} {...props} />} />
      </Switch>
      */

export default routes