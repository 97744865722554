import React, { PureComponent } from 'react';
import { Layout, Row, Col } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import marked from 'marked';
import AppHeader from './Header';
import Edit from './Edit';
import Login from './Login';
import Main from './Main';

import {
  Route,
  Switch,
  Redirect
} from 'react-router-dom'
const ReactMarkdown = require('react-markdown')
const {
  Header, Footer, Sider, Content,
} = Layout;


export default class BaseContainer extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <div>
        {this.props.client.auth.isLoggedIn && (
          <Layout>
            <AppHeader client={this.props.client} className="App-header" {...this.props} />
            <Content>
              {this.props.location.pathname === "/" ? <Main client={this.props.client} {...this.props} /> :
                <Switch>
                  <Route path="/edit" render={(props) => (<Edit client={this.props.client} {...this.props}/>)}/>
                </Switch>
              }
            </Content>
          </Layout>)}
        {!this.props.client.auth.isLoggedIn && (<Redirect to="/login" />)}
      </div>
    );
  }
}

