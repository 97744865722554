import React from 'react';
import ReactDOM from 'react-dom';
import './styles/css/site.css';
import * as serviceWorker from './serviceWorker';
import routes from './routes';
const {
  Stitch,
  RemoteMongoClient,
  AnonymousCredential
} = require('mongodb-stitch-browser-sdk');

const client = Stitch.initializeDefaultAppClient('cesperanceadmin-ttmsz');


ReactDOM.render(routes(client), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
