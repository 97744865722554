import React, { PureComponent } from 'react'
import { Layout, Menu, Row, Col, Dropdown } from 'antd'
import {IoMdPerson, IoMdNavigate} from 'react-icons/io'
import {
  Link
} from 'react-router-dom'

const { Header } = Layout
const SubMenu = Menu.SubMenu;

class AppHeader extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      key: '1',
      visible: false,
      authenticated: null
    }
  }


  _handleMenuClick = async (e) => {
    if (e.key === '1') {
      this.setState({ visible: false });
    }
    if (e.key === '7') {
      this.props.client.auth.logout().then(() => {
        this.props.history.push('/')
      })
    }
  }
  render() {

    return (
      <Header className="st-header">
        <Row>
          <Col xs={20} md={6}>
            <div className="logo">
              <a href="/">
                Admin
              </a>
            </div>
          </Col>
          <Col xs={0} md={18}>
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={['1']}
              style={{ lineHeight: '64px' }}
              className="st-header-right"
              onClick={this._handleMenuClick}
            >
              <Menu.Item key="1"><Link to="/">Home</Link></Menu.Item>
              <SubMenu title={<IoMdPerson size={30} className="st-header-dropdown-icon" />}>
                <Menu.Item key="7">
                  Log out
              </Menu.Item>
              </SubMenu>
            </Menu>
          </Col>
          <Col md={0} xs={4}>
            <div className="mynav-dropdown">
              <Dropdown overlay={menu}>
                <a className="ant-dropdown-link" href="/">
                  <IoMdNavigate size={30} className="settler-header-dropdown-icon" />
                </a>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </Header>
    )
  }

}

const menu = (

  <Menu
    theme="light"
    defaultSelectedKeys={['2']}
    style={{ lineHeight: '64px' }}
  >
    <Menu.Item key="1">nav 1</Menu.Item>
    <Menu.Item key="2">nav 2</Menu.Item>
    <Menu.Item key="3">nav 3</Menu.Item>
  </Menu>

)
export default AppHeader