import React, { PureComponent } from 'react'
import { Row, Col, Table, Dropdown, Menu, Button } from 'antd'
import { IoMdMore } from 'react-icons/io'
import { RemoteMongoClient } from 'mongodb-stitch-browser-sdk';
import { ObjectID } from 'bson';
const { Column } = Table
const dbook_abbrv = 'CE',
  dlanguage = 'fr'
const dbname = 'chants_desperance'
class Main extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      db: this.props.client.getServiceClient(RemoteMongoClient.factory, 'mongodb-atlas').db(dbname),
      perPage: 20,
      songs: [],
      filter: {
        book_abbrv: dbook_abbrv,
        language: dlanguage
      },
      _key: { book_abbrv: dbook_abbrv, language: dlanguage },
      default: {
        book_abbrv: dbook_abbrv,
        language: dlanguage
      }
    }
  }

  async componentDidMount() {
    this._fetchSongs()
  }

  editSong = (e) => {
    const id = new ObjectID(e.target.value._id)
    this.props.history.push(`/edit${id.toHexString()}`)
  }
  addSong = () => {
    this.props.history.push(`/new`)
  }
  _fetchSongs = async () => {
    const songs = await this.state.db.collection('songs').find(this.state.filter, { sort: { book_abbrv: 1, language: 1, num: 1, } }).toArray()
    this.setState({
      songs
    })

  }
  _filter = (e, field) => {
    const value = e.key
    let filter = this.state.filter
    let key = this.state._key
    filter[field] = value
    key[field] = e.key
    this.setState({
      filter,
      _key: key
    }, () => {
      this._fetchSongs()

    })

  }
  render() {
    return (

      <Row className="st-apps-main" type="flex" justify="center" >

        <Col xs={24} md={20} lg={16}>
          <h1 className="title">My Songs</h1>
          <Button size={"large"} type="primary" onClick={this.addSong}>Add a Song</Button>
          {this.state.songs.length > 0 &&
            <div>
              <Row style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Dropdown.Button id="book_abbrv" overlay={<Menu
                  theme="light"
                  style={{ lineHeight: '64px' }}
                  onClick={(e) => { this._filter(e, "book_abbrv") }}
                  defaultSelectedKeys={[this.state.default.book_abbrv]}
                >
                  <Menu.Item key="CE">Chant Esperance</Menu.Item>
                  <Menu.Item key="MJ">Melodies Joyeuses</Menu.Item>
                  <Menu.Item key="EE">Echos des Elus</Menu.Item>
                  <Menu.Item key="VR">La Voix du Reveil</Menu.Item>
                  <Menu.Item key="RN">Reveillons-Nous</Menu.Item>
                  <Menu.Item key="HC">Haiti Chante</Menu.Item>
                  <Menu.Item key="GA">Gloire a l'Agneau</Menu.Item>
                  <Menu.Item key="OR">L'Ombre du Réveil</Menu.Item>
                  <Menu.Item key="RNC">Reveillons-Nous Chretiens</Menu.Item>
                </Menu>}>{this.state._key.book_abbrv}</Dropdown.Button>
                <Dropdown.Button id="language" overlay={<Menu
                  theme="light"
                  style={{ lineHeight: '64px' }}
                  onClick={(e) => { this._filter(e, "language") }}
                  defaultSelectedKeys={this.state.default.language}
                >
                  <Menu.Item key="fr">Francais</Menu.Item>
                  <Menu.Item key="ht">Creole</Menu.Item>
                </Menu>}>{this.state._key.language}</Dropdown.Button>
                <Button onClick={() => { this.setState({ filter: {}, _key: { book_abbrv: "", language: "" } }, () => { this._fetchSongs() }) }}>Clear</Button>
              </Row>
              <Table pagination={{ pageSize: 30 }} dataSource={this.state.songs} size="small" rowKey={record => { const id = new ObjectID(record._id); return id.toHexString() }}>

                <Column
                  title='Num'
                  dataIndex='num'
                  key='num'
                />
                <Column
                  title='Book'
                  dataIndex='book_abbrv'
                  key='book_abbrv'
                />
                <Column
                  title='Language'
                  dataIndex='language'
                  key='language'
                />
                <Column
                  title='Title'
                  dataIndex='title'
                  key='title'
                  render={(text, record) => {
                    const id = new ObjectID(record._id);
                    const path = `/edit?id=${id.toHexString()}`;
                    return <a href={path}>{text}</a>
                  }}
                />
              </Table>
            </div>
          }
          {this.state.songs.length === 0 && <Row className="st-apps-main" type="flex" justify="center" >
            <Col xs={24} md={20} lg={16} style={{ alignItems: 'center' }}>
              <h2>No songs yet!</h2>
            </Col>
          </Row>
          }
        </Col>
      </Row>
    )
  }
}
const menu = (

  <Menu
    theme="light"
    style={{ lineHeight: '64px' }}
  >
    <Menu.Item key="EDIT">Edit</Menu.Item>
    <Menu.Item key="DELETE">Delete</Menu.Item>
  </Menu>

)
const langMenu = (

  <Menu
    theme="light"
    style={{ lineHeight: '64px' }}

  >
    <Menu.Item key="fr">Francais</Menu.Item>
    <Menu.Item key="ht">Creole</Menu.Item>
  </Menu>

)
const bookMenu = (
  <Menu
    theme="light"
    style={{ lineHeight: '64px' }}
  >
    <Menu.Item key="CE">Chant Esperance</Menu.Item>
    <Menu.Item key="MJ">Melodies Joyeuses</Menu.Item>
    <Menu.Item key="EE">Echos des Elus</Menu.Item>
    <Menu.Item key="VR">La Voix du Reveil</Menu.Item>
    <Menu.Item key="RN">Reveillons-Nous</Menu.Item>
    <Menu.Item key="HC">Haiti Chante</Menu.Item>
    <Menu.Item key="GA">Glorie a l'agneau</Menu.Item>
    <Menu.Item key="RNV">Glorie a l'agneau</Menu.Item>
  </Menu>
)
export default Main